import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { Giftcard as Page } from "../components/Giftcard/Giftcard"
import { useCore } from "../hooks/useCore"
import { useQueries } from "../hooks/useQueries"
import { useShopify } from "../hooks/useShopify"

import { useHotjar } from '../hooks/useHotjar'
import { useLocation } from "../hooks/useLocation"

export const query = graphql`
  query {
    page: sanityPageGiftcard {
      title
      headerContent: _rawHeaderContent(resolveReferences: { maxDepth: 8 })
      footerContent: _rawFooterContent(resolveReferences: { maxDepth: 8 })
      settingGiftCardProduct: settingGiftCardProduct {
        shopify {
          id: shopifyId
          handle: shopifyHandle
          raw: shopifyRaw
        }
        tags
      }
      additionalGiftCardTitle
      additionalGiftCardDescription: _rawAdditionalGiftCardDescription(
        resolveReferences: { maxDepth: 2 }
      )
      additionalGiftCardCustomAmountLabel
      additionalGiftCardCustomAmountPlaceholder
      additionalGiftCardReceiverEmailLabel
      additionalGiftCardConfirmReceiverEmailLabel
      additionalGiftCardMessageLabel
      additionalGiftCardLengthMessage
      additionalBalanceTitle
      additionalBalanceFormTitle
      additionalBalanceFormCardNumberLabel
      additionalBalanceFormPinLabel
      additionalBalanceFormCheckButtonText
      additionalBalanceResultTitle
      additionalBalanceResultMoneyLabel
      additionalBalanceResultExpiryLabel
      additionalBalanceResultCardNumberLabel
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const {
    helpers: { encodeShopifyId },
  } = useCore()

  const {
    localQueries: { GET_PRODUCT_AVAILABILITY },
  } = useQueries()

  const { location } = useLocation()

  const { useLazyQuery } = useShopify()

  const {
    page: {
      settingGiftCardProduct: {
        shopify: { id, handle },
      },
    },
  } = data

  const [
    getVariants,
    { data: liveVariants, called: getVariantsCalled, loading, error },
  ] = useLazyQuery(GET_PRODUCT_AVAILABILITY, {
    fetchPolicy: `cache-and-network`,
    nextFetchPolicy: "cache-first",
    variables: {
      id: encodeShopifyId(id, "Product"),
      countryCode: "AU",
      handle,
      firstVariants: 2,
      countryCode: location,
    },
  })

  useEffect(() => {
    if (!handle || getVariantsCalled) return
    getVariants()
  }, [handle, getVariantsCalled, getVariants])

  if (error) console.error(error)

  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("giftcard_page")
  
  return (
    <Page {...props} liveVariants={liveVariants} loading={loading} {...data} />
  )
}
export default Component
