import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw``}
`

export const Section = tw.div`
  max-w-107 mx-auto flex flex-col md:flex-row py-4 md:py-6 md:justify-between border-t border-navy border-opacity-25
`

export const Left = tw.div`w-full md:w-1/3 md:max-w-28 mb-1-6`

export const Right = tw.div`w-full md:w-3/5 max-w-63`

export const Subtitle = tw.h3`
  uppercase text-20 md:text-25 leading-1.5 md:leading-1.4 tracking-5 font-bold mb-2-4
`
