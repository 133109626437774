import React from "react"

import {
  Wrapper,
  Top,
  Bottom,
  Title,
  CardNumber,
  Section,
  SectionTitle,
  Balance,
  Expiry,
  Price,
} from "./GiftCardBalanceResultStyles"
import { withGiftCardBalanceResult } from "./withGiftCardBalanceResult"

export const GiftCardBalanceResult = withGiftCardBalanceResult(
  ({
    number,
    expiry,
    amount,
    additionalBalanceResultTitle,
    additionalBalanceResultMoneyLabel,
    additionalBalanceResultExpiryLabel,
    additionalBalanceResultCardNumberLabel,
  }) => (
    <Wrapper>
      <Top>
        <Title>{additionalBalanceResultTitle}</Title>
        <CardNumber>
          {additionalBalanceResultCardNumberLabel}&nbsp;
          {number}
        </CardNumber>
      </Top>
      <Bottom>
        <Section>
          <SectionTitle>{additionalBalanceResultMoneyLabel}</SectionTitle>
          <Balance>
            <Price>$</Price>
            <Price large>{amount?.[0]}</Price>
            {Boolean(amount?.[1]) && <Price>.{amount?.[1]}</Price>}
          </Balance>
        </Section>
        <Section>
          <SectionTitle>{additionalBalanceResultExpiryLabel}</SectionTitle>
          <Expiry>{expiry}</Expiry>
        </Section>
      </Bottom>
    </Wrapper>
  ),
)
