import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import dayjs from "dayjs"

export const withGiftCardBalanceResult = Component => ({
  result,
  name = "GiftCardBalanceResult",
}) => {
  const { page } = useStaticQuery(graphql`
    query SANITY_PAGE_GIFTCARD_BALANCE_RESULT {
      page: sanityPageGiftcard {
        additionalBalanceResultTitle
        additionalBalanceResultMoneyLabel
        additionalBalanceResultExpiryLabel
        additionalBalanceResultCardNumberLabel
      }
    }
  `)

  const {
    additionalBalanceResultTitle,
    additionalBalanceResultMoneyLabel,
    additionalBalanceResultExpiryLabel,
    additionalBalanceResultCardNumberLabel,
  } = page || {}

  const { number, amount, expiry } = result || {}

  const formattedNumber = number
    ?.split("")
    ?.map((v, i) => (i < number.length - 4 ? "*" : v))
    ?.join("")

  const formattedExpiry = dayjs(expiry)?.format("MM/YY")

  const formattedAmount = amount?.split(".")

  Component.displayName = name
  return (
    <Component
      number={formattedNumber}
      expiry={formattedExpiry}
      amount={formattedAmount}
      additionalBalanceResultTitle={additionalBalanceResultTitle}
      additionalBalanceResultMoneyLabel={additionalBalanceResultMoneyLabel}
      additionalBalanceResultExpiryLabel={additionalBalanceResultExpiryLabel}
      additionalBalanceResultCardNumberLabel={
        additionalBalanceResultCardNumberLabel
      }
    />
  )
}
