import React, { useEffect, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"

export const withGiftCardForm =
  Component =>
  ({ name = "GiftCardForm", giftCardVariants }) => {
    const { page } = useStaticQuery(graphql`
      query SANITY_PAGE_GIFTCARD_FORM {
        page: sanityPageGiftcard {
          settingDisablePhysicalGiftcard
          settingDisableDigitalGiftcard
          settingGiftCardPresetValue
          settingGiftCardCustomAmountMin
          settingGiftCardCustomAmountMax
          additionalGiftCardCustomAmountErrorMessage
          additionalGiftCardSenderLabel
          additionalGiftCardReceiverLabel
          additionalGiftCardTypeLabel
          additionalGiftCardReceiverEmailLabel
          additionalGiftCardConfirmReceiverEmailLabel
          additionalDigitalGiftCardLabel
          additionalDigitalGiftCardDescription: _rawAdditionalDigitalGiftCardDescription(
            resolveReferences: { maxDepth: 2 }
          )
          additionalPhysicalGiftCardLabel
          additionalPhysicalGiftCardDescription: _rawAdditionalPhysicalGiftCardDescription(
            resolveReferences: { maxDepth: 2 }
          )
          additionalDigitalGiftCardSelectAmountLabel
          additionalPhysicalGiftCardSelectAmountLabel
          additionalGiftCardCustomAmountOptionLabel
          additionalGiftCardCustomAmountLabel
          settingGiftCardMaxLength
          settingSenderMaxLength
          additionalGiftCardMessageLabel
          additionalGiftCardLengthMessage
        }
      }
    `)

    const {
      settingDisablePhysicalGiftcard,
      settingDisableDigitalGiftcard,
      settingGiftCardPresetValue,
      settingGiftCardCustomAmountMin,
      settingGiftCardCustomAmountMax,
      additionalGiftCardCustomAmountErrorMessage,
      additionalGiftCardSenderLabel,
      additionalGiftCardReceiverLabel,
      additionalGiftCardTypeLabel,
      additionalGiftCardReceiverEmailLabel,
      additionalGiftCardConfirmReceiverEmailLabel,
      additionalDigitalGiftCardLabel,
      additionalDigitalGiftCardDescription,
      additionalPhysicalGiftCardLabel,
      additionalPhysicalGiftCardDescription,
      additionalDigitalGiftCardSelectAmountLabel,
      additionalPhysicalGiftCardSelectAmountLabel,
      additionalGiftCardCustomAmountOptionLabel,
      additionalGiftCardCustomAmountLabel,
      settingGiftCardMaxLength,
      settingSenderMaxLength,
      additionalGiftCardMessageLabel,
      additionalGiftCardLengthMessage,
    } = page || {}

    const {
      config: {
        settings: { giftCardConstraints },
      },
    } = useApp()

    const { addToCart, loading } = useCart()

    const digitalCardVariant = giftCardVariants?.find(variant =>
      variant?.title?.toLowerCase()?.includes(giftCardConstraints.DIGITAL),
    )

    const [data, setData] = useState({
      senderName: "",
      receiverName: "",
      receiverEmail: "",
      confirmReceiverEmail: "",
      message: "",
    })

    const [selectedVariantSku, setSelectedVariantSku] = useState(() => {
      return giftCardVariants?.filter((v) => v?.sku?.includes('-e-'))?.[0]?.sku || giftCardVariants[0]?.sku;
    })

    const [giftCardType, setGiftCardType] = useState(
      digitalCardVariant?.id
        ? giftCardConstraints?.DIGITAL
        : giftCardConstraints?.PHYSICAL,
    )

    const handleChange = ({ target: { name, value } }) => {
      let sanitisedValue = value

      if (name?.toLowerCase()?.includes("receiveremail")) {
        sanitisedValue = value?.trim()
      }

      setData(prevState => ({
        ...prevState,
        [name]: sanitisedValue,
      }))
    }

    const handleGiftCardTypeChange = type => {
      setGiftCardType(type)
    }

    const handleAmountChange = ({ target: { value } }) => {
      setSelectedVariantSku(value)
    }

    const amountOptions = useMemo(
      () =>
        giftCardVariants
          ?.filter(variant =>
            giftCardType === giftCardConstraints.DIGITAL
              ? variant?.sku?.toLowerCase()?.includes("e")
              : giftCardType === giftCardConstraints.PHYSICAL
              ? variant?.sku?.toLowerCase()?.includes("p")
              : false,
          )
          .map(variant => ({
            label: variant.title.split(" / ")[0],
            value: variant.sku,
          })),
      [giftCardVariants, giftCardType],
    )

    const physicalCardLineItemProperties = [
      {
        key: giftCardConstraints.SENDER,
        value: data.senderName,
      },
      {
        key: giftCardConstraints.RECEIVER,
        value: data.receiverName,
      },
      {
        key: giftCardConstraints.MESSAGE,
        value: data.message,
      },
      {
        key: "_promotionalExclusive",
        value: "true",
      },
    ]
    const digitalCardLineItemProperties = [
      ...physicalCardLineItemProperties,
      {
        key: giftCardConstraints.RECEIVER_EMAIL,
        value: data.receiverEmail,
      },
    ]

    const handleAddToCart = async event => {
      event.preventDefault()

      const selectedVariant = giftCardVariants.find(
        variant => variant.sku === selectedVariantSku,
      )

      await addToCart(
        { id: "", tags: [] },
        selectedVariant,
        1,
        giftCardType === giftCardConstraints?.DIGITAL
          ? digitalCardLineItemProperties
          : physicalCardLineItemProperties,
      )
    }

    Component.displayName = name
    return (
      <Component
        data={data}
        handleChange={handleChange}
        settingDisablePhysicalGiftcard={settingDisablePhysicalGiftcard}
        settingDisableDigitalGiftcard={settingDisableDigitalGiftcard}
        additionalGiftCardSenderLabel={additionalGiftCardSenderLabel}
        additionalGiftCardReceiverLabel={additionalGiftCardReceiverLabel}
        additionalGiftCardTypeLabel={additionalGiftCardTypeLabel}
        giftCardType={giftCardType}
        handleGiftCardTypeChange={handleGiftCardTypeChange}
        giftCardTypeDigital={giftCardConstraints?.DIGITAL}
        giftCardTypePhysical={giftCardConstraints?.PHYSICAL}
        additionalDigitalGiftCardLabel={additionalDigitalGiftCardLabel}
        additionalDigitalGiftCardDescription={
          additionalDigitalGiftCardDescription
        }
        additionalPhysicalGiftCardLabel={additionalPhysicalGiftCardLabel}
        additionalPhysicalGiftCardDescription={
          additionalPhysicalGiftCardDescription
        }
        additionalGiftCardReceiverEmailLabel={
          additionalGiftCardReceiverEmailLabel
        }
        additionalGiftCardConfirmReceiverEmailLabel={
          additionalGiftCardConfirmReceiverEmailLabel
        }
        handleAmountChange={handleAmountChange}
        amountOptions={amountOptions}
        additionalDigitalGiftCardSelectAmountLabel={
          additionalDigitalGiftCardSelectAmountLabel
        }
        additionalPhysicalGiftCardSelectAmountLabel={
          additionalPhysicalGiftCardSelectAmountLabel
        }
        settingGiftCardMaxLength={settingGiftCardMaxLength}
        settingSenderMaxLength={settingSenderMaxLength}
        additionalGiftCardMessageLabel={additionalGiftCardMessageLabel}
        additionalGiftCardLengthMessage={additionalGiftCardLengthMessage}
        handleAddToCart={handleAddToCart}
        loading={loading}
      />
    )
  }
