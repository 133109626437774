import tw, { styled } from "twin.macro"
import { StyledButton } from "../../Styled/Button"

export const Wrapper = tw.div``

export const Title = tw.h3`
  mb-2-4 uppercase text-14 font-semibold leading-1.71 tracking-10
`

export const Fields = tw.div`
  grid grid-cols-1 md:grid-cols-2 gap-x-1-6
`

export const Submit = styled(StyledButton)``
