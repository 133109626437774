import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"

import { useApp } from "../../../hooks/useApp"
import { useFunctions } from "../../../hooks/useFunctions"

export const withGiftCardBalance = Component => ({
  name = "GiftCardBalance",
}) => {
  const { page } = useStaticQuery(graphql`
    query SANITY_PAGE_GIFTCARD_BALANCE {
      page: sanityPageGiftcard {
        additionalBalanceFormTitle
        additionalBalanceFormCardNumberLabel
        additionalBalanceFormPinLabel
        additionalBalanceFormCheckButtonText
      }
    }
  `)

  const {
    additionalBalanceFormTitle,
    additionalBalanceFormCardNumberLabel,
    additionalBalanceFormPinLabel,
    additionalBalanceFormCheckButtonText,
  } = page || {}

  const {
    config: {
      services: {
        giftcard: { balance: balanceURL },
      },
    },
  } = useApp()
  
  const { getGiftcard } = useFunctions()

  const [requestData, setRequestData] = useState({
    cardNumber: "",
    password: "",
  })

  const [resultData, setResultData] = useState({
    number: "",
    expiry: "",
    amount: "",
  })

  const [errors, setErrors] = useState(null)
  const [success, setSuccess] = useState(false)

  const handleChange = ({ target: { name, value } }) => {
    setRequestData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async event => {
    event.preventDefault()
    
    const resp = await getGiftcard({
      number: requestData.cardNumber,
      pin: requestData.password,
    })

    const data = resp?.data

    if (data?.voucherNumber) {
      setSuccess(true)
      setResultData({
        number: data.voucherNumber,
        expiry: data.expiryDate,
        amount: data.availableAmount,
      })
    } else {
      setSuccess(false)
      setErrors(data?.errors?.[0] || "Invalid Gift Card")
      console.error("error getting giftcard balance", data?.errors)
    }
    
  }

  Component.displayName = name
  return (
    <Component
      requestData={requestData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      resultData={resultData}
      success={success}
      errors={errors}
      additionalBalanceFormTitle={additionalBalanceFormTitle}
      additionalBalanceFormCardNumberLabel={
        additionalBalanceFormCardNumberLabel
      }
      additionalBalanceFormPinLabel={additionalBalanceFormPinLabel}
      additionalBalanceFormCheckButtonText={
        additionalBalanceFormCheckButtonText
      }
    />
  )
}
