import React from "react"

import { withGiftCardBalance } from "./withGiftCardBalance"
import { Wrapper, Title, Fields, Submit } from "./GiftCardBalanceStyles"
import { TextField } from "../../TextField/TextField"
import { GiftCardBalanceResult } from "./Result/GiftCardBalanceResult"
import { TinyBody } from "../../Styled/Text"

export const GiftCardBalance = withGiftCardBalance(
  ({
    requestData,
    handleChange,
    handleSubmit,
    resultData,
    success,
    errors,
    additionalBalanceFormTitle,
    additionalBalanceFormCardNumberLabel,
    additionalBalanceFormPinLabel,
    additionalBalanceFormCheckButtonText,
  }) => (
    <Wrapper>
      <Title>{additionalBalanceFormTitle}</Title>
      {success ? (
        <GiftCardBalanceResult result={resultData} />
      ) : (
        <form onSubmit={handleSubmit}>
          <Fields>
            <TextField
              name="cardNumber"
              type="text"
              value={requestData?.cardNumber}
              onChange={handleChange}
              label={additionalBalanceFormCardNumberLabel}
              required
              spacing
            />
            <TextField
              name="password"
              type="password"
              value={requestData?.password}
              onChange={handleChange}
              label={additionalBalanceFormPinLabel}
              required
              spacing
            />
          </Fields>
          {typeof errors === "string" && Boolean(errors) && (
            <TinyBody colour="red" spacing="default">
              {errors}
            </TinyBody>
          )}
          <Submit size="primary" type="submit" wide>
            {additionalBalanceFormCheckButtonText}
          </Submit>
        </form>
      )}
    </Wrapper>
  ),
)
