import React, { useMemo } from "react"

import { useShopify } from "../../hooks/useShopify"
import { useLocation } from "../../hooks/useLocation"

export const withGiftcard =
  Component =>
  ({ name = "Giftcard", page, liveVariants }: any) => {
    const { edgeNormaliser } = useShopify()
    const { location } = useLocation()

    const {
      headerContent,
      settingGiftCardProduct,
      additionalGiftCardTitle,
      additionalGiftCardDescription,
      additionalBalanceTitle,
      footerContent,
    } = page || {}

    let rawProduct = undefined
    if (settingGiftCardProduct.shopify?.raw) {
      try {
        rawProduct = JSON.parse(settingGiftCardProduct.shopify?.raw)
      } catch (e) {
        console.error("Error parsing raw shopify product")
      }
    }

    const variants = edgeNormaliser(
      liveVariants?.product?.variants || rawProduct.variants,
    )

    const isNZ = useMemo(() => {
      return location === "NZ" ? true : false
    }, [location])

    Component.displayName = name
    return (
      <Component
        headerContent={headerContent}
        additionalGiftCardTitle={additionalGiftCardTitle}
        additionalGiftCardDescription={additionalGiftCardDescription}
        additionalBalanceTitle={additionalBalanceTitle}
        footerContent={footerContent}
        giftCardVariants={variants}
        isNZ={isNZ}
      />
    )
  }
