import tw, { styled } from "twin.macro"
import { CheckboxButton } from "../../Checkbox/Checkbox"
import { RichText } from "../../RichText/RichText"
import { TextArea } from "../../TextArea/TextArea"
import { StyledButton } from "../../Styled/Button"

export const Wrapper = tw.div``

export const Fields = tw.div`
 grid grid-cols-1 md:grid-cols-2 gap-x-1-6
`

export const Label = tw.p`
  text-14 leading-1.71 mb-1-6
`
export const Selector = tw.div`
  border-b border-navy border-opacity-20 pb-1-6 md:pb-2-4 mb-1-6 md:mb-2-4
`

export const Checkboxes = tw.div`
  flex flex-row
`

export const Description = styled(RichText)`
  ${tw`mt-1-6`}
`

export const StyledCheckbox = styled(CheckboxButton)`
  ${tw`mr-4`}
`

export const StyledTextArea = styled(TextArea)`
  ${tw`mb-2-4`}
`

export const AddToCart = styled(StyledButton)``
